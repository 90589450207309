import { graphql } from "gatsby";
import React from "react";
import { CompletedProjects } from "../CompletedProjects/CompletedProjects";
import "./styles.css";

const MetalConstructionsExamples = ({ title, examples }) => {
  return <CompletedProjects title={title} examples={examples} />;
};

MetalConstructionsExamples.getLivePreviewData = ({ data, getAsset }) => ({
  metalConstructionsExamples: {
    ...data.metalConstructionsExamples,
    examples: data.metalConstructionsExamples.map(example => ({
      ...example,
      photo: getAsset(example.photo).url,
    })),
  },
});

const metalConstructionsExamplesQuery = graphql`
  fragment MetalConstructionsExamplesFragment on MarkdownRemarkFrontmatter {
    metalConstructionsExamples {
      title
      examples {
        photo {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { MetalConstructionsExamples, metalConstructionsExamplesQuery };
