import cn from "classnames";
import React from "react";
import { Image } from "../../Image";
import "./styles.css";

const MetalConstructionsHeroBackground = ({ className, background, alt }) => {
  return (
    <div className={cn(className, "MetalConstructionsHeroBackground")}>
      <Image className="MetalConstructionsHeroBackground__image" image={background} alt={alt} />
    </div>
  );
};

export { MetalConstructionsHeroBackground };
