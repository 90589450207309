import React from "react";

const BackgroundTop = props => {
  return (
    <svg viewBox="0 0 193 302" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="193" height="302">
        <path fill="#fff" d="M0 0h193v302H0z" />
      </mask>
      <g mask="url(#a)">
        <g opacity=".54">
          <path
            d="M139.857 151.73c0 7.56 6.21 13.719 13.832 13.719 7.621 0 13.832-6.159 13.832-13.719s-6.211-13.72-13.832-13.72c-7.657.035-13.832 6.16-13.832 13.72zm-14.115 0c0-15.295 12.491-27.72 27.947-27.72 15.419 0 27.946 12.39 27.946 27.72 0 15.329-12.491 27.719-27.946 27.719-15.456 0-27.947-12.425-27.947-27.719z"
            fill="#DADADA"
          />
          <path
            d="M139.857 151.73c0 7.56 6.21 13.719 13.832 13.719 7.621 0 13.832-6.159 13.832-13.719s-6.211-13.72-13.832-13.72c-7.657.035-13.832 6.16-13.832 13.72zm-14.115 0c0-15.295 12.491-27.72 27.947-27.72 15.419 0 27.946 12.39 27.946 27.72 0 15.329-12.491 27.719-27.946 27.719-15.456 0-27.947-12.425-27.947-27.719z"
            fill="url(#paint0_linear)"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-305.159-279.685a1.456 1.456 0 00.007 2.063l384.47 381.176a1.466 1.466 0 002.068-.007 1.456 1.456 0 00-.007-2.063l-384.47-381.176a1.464 1.464 0 00-2.068.007z"
          fill="#000"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.73 161.802a4.37 4.37 0 00-.018-6.19L-219.89-161.399a4.392 4.392 0 00-6.203.019 4.368 4.368 0 00.019 6.189L93.527 161.82a4.394 4.394 0 006.203-.018z"
          fill="#DADADA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.73 161.802a4.37 4.37 0 00-.018-6.19L-219.89-161.399a4.392 4.392 0 00-6.203.019 4.368 4.368 0 00.019 6.189L93.527 161.82a4.394 4.394 0 006.203-.018z"
          fill="url(#paint1_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M172.836 264.657a4.37 4.37 0 00-.019-6.19l-319.601-317.01a4.393 4.393 0 00-6.203.018 4.37 4.37 0 00.018 6.19l319.602 317.01a4.393 4.393 0 006.203-.018z"
          fill="#DADADA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M172.836 264.657a4.37 4.37 0 00-.019-6.19l-319.601-317.01a4.393 4.393 0 00-6.203.018 4.37 4.37 0 00.018 6.19l319.602 317.01a4.393 4.393 0 006.203-.018z"
          fill="url(#paint2_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.226 191.55c-2.501-2.269-2.501-5.888 0-8.156 2.58-2.342 6.821-2.342 9.402 0 1.773 1.608 2.269 3.858 1.588 5.81a1.463 1.463 0 002.762.96c1.072-3.073.244-6.546-2.383-8.929-3.696-3.354-9.64-3.354-13.337 0-3.776 3.426-3.776 9.048 0 12.474 2.337 2.12 5.527 2.869 8.461 2.368a1.458 1.458 0 10-.492-2.876c-2.144.366-4.4-.198-6.002-1.651z"
          fill="#000"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="125.742"
          y1="151.73"
          x2="181.635"
          y2="151.73"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fff" />
          <stop offset="1" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="161.489"
          y1="32.307"
          x2="-222.982"
          y2="32.307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fff" />
          <stop offset="1" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="234.594"
          y1="135.162"
          x2="-149.877"
          y2="135.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#fff" />
          <stop offset="1" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { BackgroundTop };
