import { graphql } from "gatsby";
import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { ContactUsModal } from "../components/ContactUsModal";
import { Layout } from "../components/Layout";
import { MetalConstructionsAdvantages } from "../components/MetalConstructionsAdvantages";
import { MetalConstructionsExamples } from "../components/MetalConstructionsExamples";
import { MetalConstructionsHero } from "../components/MetalConstructionsHero";

const MetalConstructionsTemplate = ({ layout, ...props }) => {
  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState();
  const handleOpenContactUsModal = useCallback(() => setIsOpenContactUsModal(true), []);
  const handleCloseContactUsModal = useCallback(() => setIsOpenContactUsModal(false), []);
  return (
    <Layout {...layout} onOpenContactUsModal={handleOpenContactUsModal} lang={props.layoutId}>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <MetalConstructionsHero {...props.metalConstructionsHero} onOpenContactUsModal={handleOpenContactUsModal} />
      <MetalConstructionsAdvantages {...props.metalConstructionsAdvantages} />
      <MetalConstructionsExamples {...props.metalConstructionsExamples} />

      <ContactUsModal isOpen={isOpenContactUsModal} onClose={handleCloseContactUsModal} {...layout.contactUsModal} />
    </Layout>
  );
};

MetalConstructionsTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...MetalConstructionsHero.getLivePreviewData(props),
  ...MetalConstructionsAdvantages.getLivePreviewData(props),
  ...MetalConstructionsExamples.getLivePreviewData(props),
});

const MetalConstructionsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return <MetalConstructionsTemplate layout={data.layout.frontmatter} {...frontmatter} />;
};

const pageQuery = graphql`
  query MetalConstructionsTemplate($id: String!, $layoutId: String!) {
    layout: markdownRemark(frontmatter: { id: { eq: $layoutId } }) {
      frontmatter {
        ...LayoutFragment
        ...ContactUsModalFragment
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        layoutId
        seo {
          title
          description
          keywords
        }

        ...MetalConstructionsHeroFragment
        ...MetalConstructionsAdvantagesFragment
        ...MetalConstructionsExamplesFragment
      }
    }
  }
`;

export default MetalConstructionsPage;

export { pageQuery, MetalConstructionsTemplate };
