import { graphql } from "gatsby";
import React from "react";
import { MetalConstructionsHeroBackground } from "./MetalConstructionsHeroBackground";
import { MetalConstructionsHeroContent } from "./MetalConstructionsHeroContent";
import "./styles.css";

const MetalConstructionsHero = ({ background, alt, subtitle, title, text, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className="[ MetalConstructionsHero ] [ Content sm:Content_medium ]">
      <MetalConstructionsHeroContent
        className="MetalConstructionsHero__content"
        subtitle={subtitle}
        title={title}
        text={text}
        buttonLabel={buttonLabel}
        onOpenContactUsModal={onOpenContactUsModal}
      />

      <MetalConstructionsHeroBackground
        className="MetalConstructionsHero__background"
        background={background}
        alt={alt}
      />
    </div>
  );
};

MetalConstructionsHero.getLivePreviewData = ({ data, getAsset }) => ({
  metalConstructionsHero: {
    ...data.metalConstructionsHero,
    background: getAsset(data.metalConstructionsHero.background).url,
  },
});

const metalConstructionsHeroQuery = graphql`
  fragment MetalConstructionsHeroFragment on MarkdownRemarkFrontmatter {
    metalConstructionsHero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      text
      buttonLabel
    }
  }
`;

export { MetalConstructionsHero, metalConstructionsHeroQuery };
