import React from "react";

const BackgroundBottom = props => {
  return (
    <svg {...props} viewBox="0 0 223 258" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="223" height="258">
        <rect width="223" height="258" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <g opacity="0.54">
          <path
            d="M122.615 196.627C122.615 187.613 115.241 180.269 106.19 180.269C97.1401 180.269 89.7657 187.613 89.7657 196.627C89.7657 205.641 97.1401 212.985 106.19 212.985C115.283 212.943 122.615 205.641 122.615 196.627ZM139.375 196.627C139.375 214.863 124.542 229.677 106.19 229.677C87.8802 229.677 73.0058 214.905 73.0058 196.627C73.0058 178.349 87.8383 163.577 106.19 163.577C124.542 163.577 139.375 178.391 139.375 196.627Z"
            fill="#DADADA"
          />
          <path
            d="M122.615 196.627C122.615 187.613 115.241 180.269 106.19 180.269C97.1401 180.269 89.7657 187.613 89.7657 196.627C89.7657 205.641 97.1401 212.985 106.19 212.985C115.283 212.943 122.615 205.641 122.615 196.627ZM139.375 196.627C139.375 214.863 124.542 229.677 106.19 229.677C87.8802 229.677 73.0058 214.905 73.0058 196.627C73.0058 178.349 87.8383 163.577 106.19 163.577C124.542 163.577 139.375 178.391 139.375 196.627Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.947 16.7514C14.3965 18.3141 14.4041 20.8403 15.9638 22.3937L306.113 311.366C307.673 312.919 310.194 312.911 311.744 311.349C313.295 309.786 313.287 307.26 311.728 305.707L21.5786 16.7346C20.0189 15.1812 17.4975 15.1887 15.947 16.7514Z"
          fill="#DADADA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.947 16.7514C14.3965 18.3141 14.4041 20.8403 15.9638 22.3937L306.113 311.366C307.673 312.919 310.194 312.911 311.744 311.349C313.295 309.786 313.287 307.26 311.728 305.707L21.5786 16.7346C20.0189 15.1812 17.4975 15.1887 15.947 16.7514Z"
          fill="url(#paint1_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M98.0623 70.3318C96.5118 71.8945 96.5193 74.4206 98.079 75.974L388.228 364.946C389.788 366.499 392.309 366.492 393.86 364.929C395.41 363.366 395.403 360.84 393.843 359.287L103.694 70.3149C102.134 68.7615 99.6127 68.7691 98.0623 70.3318Z"
          fill="#DADADA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M98.0623 70.3318C96.5118 71.8945 96.5193 74.4206 98.079 75.974L388.228 364.946C389.788 366.499 392.309 366.492 393.86 364.929C395.41 363.366 395.403 360.84 393.843 359.287L103.694 70.3149C102.134 68.7615 99.6127 68.7691 98.0623 70.3318Z"
          fill="url(#paint2_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M161.205 230.346C163.475 232.414 163.475 235.713 161.205 237.781C158.862 239.915 155.012 239.915 152.669 237.781C151.06 236.314 150.609 234.263 151.228 232.484C151.469 231.79 151.103 231.032 150.411 230.791C149.719 230.549 148.962 230.916 148.721 231.609C147.747 234.41 148.498 237.576 150.883 239.749C154.239 242.805 159.635 242.805 162.991 239.749C166.42 236.625 166.42 231.501 162.991 228.378C160.87 226.445 157.973 225.763 155.31 226.219C154.587 226.342 154.101 227.03 154.225 227.754C154.348 228.478 155.034 228.964 155.757 228.841C157.703 228.508 159.751 229.021 161.205 230.346Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="139.375"
          y1="196.627"
          x2="73.0058"
          y2="196.627"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-40.1199"
          y1="134.792"
          x2="308.92"
          y2="134.792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="41.9953"
          y1="188.373"
          x2="391.035"
          y2="188.373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { BackgroundBottom };
