import React from "react";
import { Button } from "../../Button";
import "./styles.css";

const MetalConstructionsHeroContent = ({ className, subtitle, title, text, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className={className}>
      <p className="Font_smallHeader sm:Font_mediumText Color_middleGrey">{subtitle}</p>
      <h1 className="[ MetalConstructionsHeroContent__title ] [ Font_superHeader sm:Font_smallHeader ]">{title}</h1>
      <p className="[ MetalConstructionsHeroContent__text ] [ Font_bigText sm:Font_regularText Color_darkGrey ]">
        {text}
      </p>
      <Button className="MetalConstructionsHeroContent__button" onClick={onOpenContactUsModal}>
        {buttonLabel}
      </Button>
    </div>
  );
};

export { MetalConstructionsHeroContent };
