import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import { BackgroundBottom, BackgroundTop } from "./images";
import "./styles.css";

const MetalConstructionsAdvantages = ({ image, alt, title, text, jobs }) => {
  return (
    <div className="MetalConstructionsAdvantages__fullContainer">
      <BackgroundTop className="MetalConstructionsAdvantages__asideImageOne" />
      <BackgroundBottom className="MetalConstructionsAdvantages__asideImageTwo" />
      <div className="[ MetalConstructionsAdvantages__container ] [ Content sm:Content_medium ]">
        <div className="MetalConstructionsAdvantages__imageContainer">
          <Image className="MetalConstructionsAdvantages__image" image={image} />
        </div>

        <div className="MetalConstructionsAdvantages__description">
          <h2 className="Font_header sm:Font_smallHeader">{title}</h2>
          <p className="[ MetalConstructionsAdvantages__text ] [ Font_bigText sm:Font_regularText ]">{text}</p>
          <ul className="[ MetalConstructionsAdvantages__list ] [ Font_bigText sm:Font_regularText Color_darkGrey ]">
            {jobs.map(job => (
              <li key={job}>• {job}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

MetalConstructionsAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  metalConstructionsAdvantages: {
    ...data.metalConstructionsAdvantages,
    image: getAsset(data.metalConstructionsAdvantages.image).url,
  },
});

const metalConstructionsAdvantagesQuery = graphql`
  fragment MetalConstructionsAdvantagesFragment on MarkdownRemarkFrontmatter {
    metalConstructionsAdvantages {
      image {
        childImageSharp {
          fluid(maxWidth: 1240) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      jobs
    }
  }
`;

export { MetalConstructionsAdvantages, metalConstructionsAdvantagesQuery };
